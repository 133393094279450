<template>
  <v-card flat tile max-width="520" width="100%">
    <div class="h3 mb-2">Introduction</div>
    <div class="h7 text--text mb-2">
      Describe your professional skills, best practices and cases. It help you to increase a curiosity to your person.
    </div>
    <form @submit.prevent="reset">
      <div class="h10">Title</div>
      <v-text-field
        class="field44"
        v-model="data.short_introduction"
        :error-messages="shortErrors"
        placeholder="Ex: Professional Englkish teacher with 2 years experiance"
        clearable
        clear-icon="mdi-close-circle"
        outlined
        dense
        required
        color="secondary"
        height="44"
      ></v-text-field>
      <div class="d-flex mt-n2 align-center">
        <div class="h10">Describe your identity</div>
        <v-spacer></v-spacer>
        <div class="text--text" style="font-size: 9px">Up to 1500 symb.</div>
      </div>
      <v-textarea
        v-model="data.introduction"
        :error-messages="inErrors"
        placeholder="Ex: Hallo! I am a native teacher from Ireland."
        clearable
        clear-icon="mdi-close-circle"
        outlined
        counter
        dense
        required
        color="secondary"
        auto-grow
        maxlength="1500"
      ></v-textarea>
      <div class="h4">Media</div>
      <div class="h8 text--text mb-2">
        Add video or paste a link from Youtube where you can show your communication and professional skills and your language level
      </div>
      <div v-if="file">
        <div v-if="image">
          <v-img v-if="file.type.substr(0, 5) == 'image'" cover max-width="440" :src="image"></v-img>
          <video v-if="file.type.substr(0, 5) == 'video'" controls width="440">
            <source :src="image" />
          </video>
        </div>
        <v-card v-else flat tile max-width="440" width="100%" height="240" class="d-flex align-center justify-center">
          <div>Please wait, file upload</div>
        </v-card>
      </div>
      <div v-else>
        <div v-if="data.media">
          <v-card
            v-if="data.media.status == 'processing'"
            flat
            tile
            max-width="440"
            width="100%"
            height="240"
            class="d-flex align-center justify-center"
          >
            <v-progress-circular :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
          </v-card>
          <div v-else-if="data.media.type != 'video'">
            <v-img cover v-if="data.media || image" max-width="440" :src="image || data.media.url"></v-img>
          </div>
          <v-card v-else tile flat>
            <video controls width="440">
              <source :src="data.media.url" :poster="data.media.preview_url" />
            </video>
          </v-card>
        </div>
      </div>
      <v-btn @click="addMedia" outlined width="140" height="34" class="mt-2 me-3">
        <v-icon left>mdi-plus</v-icon> {{ data.media || image ? 'Change media' : 'Add media' }}
      </v-btn>
      <v-btn v-if="data.media" @click="deleteMedia" text width="130" height="34" color="error" class="mt-2">
        <img src="@/assets/icon/delete.svg" class="mr-1" />Remove
      </v-btn>
      <div class="mt-6">
        <v-btn @click="update" color="primary me-3" width="130" height="34">SAVE</v-btn>
        <v-btn type="submit" text width="130" height="34">Cancel</v-btn>
      </div>
    </form>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      image: '',
      file: '',
      timerId: null,
      error: [],
    };
  },
  methods: {
    addMedia() {
      const input = document.createElement('input');
      const self = this;
      input.setAttribute('type', 'file');
      input.setAttribute('accept', 'video/*,image/*');
      input.addEventListener('change', function(e) {
        if (this.files && this.files[0]) {
          self.file = this.files[0];
          console.log(self.file);
          var reader = new FileReader();
          reader.onload = (e) => {
            console.log(e.target.result);
            self.image = e.target.result;
          };
          reader.readAsDataURL(this.files[0]);
        }
      });
      input.click();
    },
    async update() {
      this.error = [];
      const formData = new FormData();
      this.data.short_introduction ? formData.append('short_introduction', this.data.short_introduction) : '';
      this.data.introduction ? formData.append('introduction', this.data.introduction) : '';
      if (this.file) {
        this.file.type.substr(0, 5) == 'image' ? formData.append('media_picture', this.file) : '';
        this.file.type.substr(0, 5) == 'video' ? formData.append('media_video', this.file) : '';
      }
      await this.$store
        .dispatch('updateProfile', formData)
        .then(() => {
          this.file = '';
          this.image = '';
        })
        .catch((e) => {
          this.error = e.response.data.error;
        });
    },
    async deleteMedia() {
      await this.$store.dispatch('deleteMediaProfile');
    },
    reset() {
      this.data.short_introduction = '';
      this.data.introduction = '';
    },
  },
  watch: {
    data() {
      clearInterval(this.timerId);
      if (this.data.media) {
        this.timerId = setInterval(() => {
          if (this.data.media.status == 'processing') {
            this.$store.dispatch('getProfile');
          } else {
            clearInterval(this.timerId);
          }
        }, 700);
      }
    },
  },
  computed: {
    data() {
      return this.$store.getters.profile;
    },
    shortErrors() {
      const errors = [];
      this.error.find((item) => item == 'short_introduction__invalid') && errors.push('Provided title is not valid');
      return errors;
    },
    inErrors() {
      const errors = [];
      this.error.find((item) => item == 'introduction__invalid') && errors.push('Provided description is not valid');
      return errors;
    },
  },
};
</script>
