<template>
  <div class="d-flex wrap">
    <v-card color="accent profile-menu" tile flat width="260">
      <div :class="link == 1 ? 'hover' : ''" @click="link = 1" class="pl-6 pr-4 pt-6 pb-4 d-flex link">
        <v-avatar color="gray" size="42">
          <v-img v-if="data.picture != null" :src="data.picture"></v-img>
          <div v-else>
            <v-icon>mdi-account mdi-36px</v-icon>
          </div>
        </v-avatar>
        <div class="ms-3">
          <div class="h7">{{ data.first_name }} {{ data.last_name }}</div>
          <div class="h10 text--text">{{ data.short_introduction }}</div>
        </div>
        <v-icon class="ms-auto" v-if="link != 1">mdi-chevron-right</v-icon>
      </div>
      <div :class="link == 2 ? 'hover' : ''" @click="link = 2" class="ps-6 pe-4 py-2 d-flex justify-space-between link">
        <div :class="link == 2 ? 'font-weight-medium' : 'text--text'" class="h8">Education</div>
        <v-icon v-if="link != 2">mdi-chevron-right</v-icon>
      </div>
      <div :class="link == 3 ? 'hover' : ''" @click="link = 3" class="ps-6 pe-4 py-2 d-flex justify-space-between link">
        <div :class="link == 3 ? 'font-weight-medium' : 'text--text'" class="h8">Introduction</div>
        <v-icon v-if="link != 3">mdi-chevron-right</v-icon>
      </div>
      <div :class="link == 4 ? 'hover' : ''" @click="link = 4" class="ps-6 pe-4 py-2 d-flex justify-space-between link">
        <div :class="link == 4 ? 'font-weight-medium' : 'text--text'" class="h8">Password</div>
        <v-icon v-if="link != 4">mdi-chevron-right</v-icon>
      </div>
    </v-card>
    <v-card tile flat width="100%" max-width="672" class="pa-6 ms-md-auto" min-height="calc(100vh - 60px)">
      <v-expand-transition>
        <div v-if="link == 1">
          <Info />
        </div>
      </v-expand-transition>
      <v-expand-transition>
        <div v-if="link == 2">
          <Education />
        </div>
      </v-expand-transition>
      <v-expand-transition>
        <div v-if="link == 3">
          <Introduction />
        </div>
      </v-expand-transition>
      <v-expand-transition>
        <div v-if="link == 4">
          <Password />
        </div>
      </v-expand-transition>
    </v-card>
  </div>
</template>

<script>
import Info from '@/components/profile/Info';
import Education from '@/components/profile/Education';
import Introduction from '@/components/profile/Introduction';
import Password from '@/components/profile/Password';

export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  components: {
    Info,
    Education,
    Introduction,
    Password,
  },
  data() {
    return {
      link: 1,
    };
  },
  computed: {
    data() {
      return this.$store.getters.profile;
    },
  },
  destroyed() {
    this.$store.dispatch('setProfileEducation', []);
  },
};
</script>

<style>
.profile-menu {
  position: fixed;
  height: calc(100vh - 60px);
  overflow: auto;
  min-width: 200px;
}
@media screen and (max-width: 959px) {
  .profile-menu {
    position: static;
  }
}
@media screen and (max-width: 599px) {
  .profile-menu {
    height: initial;
  }
}
</style>
