<template>
  <v-card flat tile max-width="320" width="100%">
    <div class="h3 mb-4">Password</div>
    <form>
      <div class="h10">Current password</div>
      <v-text-field
        class="field44"
        v-model="passwordCur"
        :error-messages="passwordCurErrors"
        :type="showCur ? 'text' : 'password'"
        :append-icon="showCur ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append="showCur = !showCur"
        placeholder="Enter current password"
        clearable
        clear-icon="mdi-close-circle"
        outlined
        dense
        required
        color="secondary"
        height="44"
      ></v-text-field>
      <div class="h10 mt-n2">New password</div>
      <v-text-field
        class="field44"
        v-model="password"
        :error-messages="passwordErrors"
        :type="show ? 'text' : 'password'"
        :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append="show = !show"
        placeholder="Enter new password"
        clearable
        clear-icon="mdi-close-circle"
        outlined
        dense
        required
        color="secondary"
        height="44"
      ></v-text-field>
      <v-btn @click="update" color="primary" width="130" height="34">SAVE</v-btn>
    </form>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      passwordCur: '',
      password: '',
      showCur: false,
      show: false,
      error: [],
    };
  },
  methods: {
    notifi(btn) {
      if (btn == 'infoMsg') {
        this.$store.dispatch('setMsg', 'Password is updated successfully');
        this.$store.dispatch('setColor', 'success');
      }
    },
    async update() {
      this.error = [];
      const data = new Object();
      data.current_password = this.passwordCur;
      data.new_password = this.password;
      await this.$store
        .dispatch('newPasswordProfile', data)
        .then(() => {
          this.notifi('infoMsg');
        })
        .catch((e) => {
          this.error = e.response.data.error;
        });
    },
  },
  computed: {
    passwordErrors() {
      const errors = [];
      this.error.find((item) => item == 'new_password__required') && errors.push('Please enter your new password');
      this.error.find((item) => item == 'new_password__invalid') &&
        errors.push('New password should contains at least one digit, one symbol and be at least 8 characters long');
      this.error.find((item) => item == 'new_password__weak') && errors.push('Sorry, provide new password weak');
      return errors;
    },
    passwordCurErrors() {
      const errors = [];
      this.error.find((item) => item == 'current_password__required') && errors.push('Please enter your current password');
      this.error.find((item) => item == 'current_password__invalid') && errors.push('Provided current password is not valid');
      return errors;
    },
  },
};
</script>
