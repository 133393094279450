<template>
  <v-card flat tile max-width="520" width="100%">
    <div class="h3 mb-2">Education</div>
    <div v-for="(item, i) in data" :key="i">
      <div class="h4 mt-4">University/Course</div>
      <div class="h8 text--text mb-2">Add relevant information that confirm your professional skills</div>
      <form>
        <div class="h10">School</div>
        <v-text-field
          class="field44"
          v-model="item.school"
          :error-messages="schoolErrors"
          placeholder="Ex: Boston University"
          clearable
          clear-icon="mdi-close-circle"
          outlined
          dense
          required
          color="secondary"
          height="44"
        ></v-text-field>
        <div class="h10 mt-n2">Degree</div>
        <v-text-field
          class="field44"
          v-model="item.degree"
          :error-messages="degreeErrors"
          placeholder="Ex: Bachelor’s"
          clearable
          clear-icon="mdi-close-circle"
          outlined
          dense
          required
          color="secondary"
          height="44"
        ></v-text-field>
        <div class="h10 mt-n2">Field of study</div>
        <v-text-field
          class="field44"
          v-model="item.study_field"
          :error-messages="studyErrors"
          placeholder="Ex: Economy"
          clearable
          clear-icon="mdi-close-circle"
          outlined
          dense
          required
          color="secondary"
          height="44"
        ></v-text-field>
        <div class="h10 mt-n2 mb-3">Years of education</div>
        <v-row>
          <v-col cols="12" sm="6" class="py-0">
            <v-text-field
              class="field44"
              v-model="item.begin_year"
              :error-messages="beginErrors"
              placeholder="From"
              clearable
              clear-icon="mdi-close-circle"
              outlined
              dense
              required
              color="secondary"
              height="44"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" class="py-0">
            <v-text-field
              class="field44"
              v-model="item.end_year"
              :error-messages="endErrors"
              placeholder="To"
              clearable
              clear-icon="mdi-close-circle"
              outlined
              dense
              required
              color="secondary"
              height="44"
            ></v-text-field>
          </v-col>
        </v-row>
        <div v-if="item.id">
          <div class="h4">Media</div>
          <div v-if="item.attachments.length < 10" class="h8 text--text mb-2">
            Add link to external documents, sites or upload photos, document.
          </div>
          <v-menu offset-y nudge-bottom="5">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-if="item.attachments.length < 10" v-bind="attrs" v-on="on" outlined width="130" height="34">
                <v-icon left>mdi-plus</v-icon> Add
              </v-btn>
            </template>
            <v-list>
              <v-list-item link @click="(modalAddLink = true), (attId = item.id)">
                <v-list-item-icon class="me-3">
                  <img src="@/assets/icon/link.svg" />
                </v-list-item-icon>
                <v-list-item-title>Link</v-list-item-title>
              </v-list-item>
              <v-list-item link @click="(modalMedia = true), (attId = item.id)">
                <v-list-item-icon class="me-3">
                  <img src="@/assets/icon/img.svg" />
                </v-list-item-icon>
                <v-list-item-title>Upload a media</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <div v-if="item.attachments.find((i) => i.type == 'pdf')">
            <div class="text--text h8 mt-4">Files</div>
            <v-divider class="mb-2 mt-1"></v-divider>
            <div v-for="i in item.attachments" :key="i.id">
              <div v-if="i.type == 'pdf'">
                <div class="d-flex align-center">
                  <div class="me-3 link" @click="openModal(i, item.id)">
                    <v-img class="d-flex align-end" v-if="i.picture" height="74" cover :src="i.picture">
                      <v-card tile color="black" width="26" height="26" class="ms-auto d-flex align-center justify-center rounded-tl-lg">
                        <v-img tile class="filter-white" contain height="16" src="@/assets/icon/edit.svg"></v-img>
                      </v-card>
                    </v-img>
                    <v-card v-else flat tile color="#eaeafb" width="80" height="74" class="d-flex align-center justify-center">
                      <img class="d-block" src="@/assets/img/empty-file.svg" />
                    </v-card>
                  </div>
                  <div>
                    <div class="h7">{{ i.title }}.{{ i.type }}</div>
                    <div><a class="url--text h7" :href="i.url" target="_blank">Open in browser</a></div>
                  </div>
                </div>
                <v-divider class="my-2"></v-divider>
              </div>
            </div>
          </div>
          <div v-if="item.attachments.find((i) => i.type == 'link')">
            <div class="text--text h8 mt-4">Links</div>
            <v-divider class="mb-2 mt-1"></v-divider>
            <div v-for="i in item.attachments" :key="i.id">
              <div v-if="i.type == 'link'">
                <div class="d-flex align-center">
                  <div class="me-3 link" @click="openModal(i, item.id)">
                    <v-img class="d-flex align-end" v-if="i.url" height="54" cover :src="i.url">
                      <v-card tile color="black" width="26" height="26" class="ms-auto d-flex align-center justify-center rounded-tl-lg">
                        <v-img tile class="filter-white" contain height="16" src="@/assets/icon/edit.svg"></v-img>
                      </v-card>
                    </v-img>
                    <v-card v-else flat tile color="#eaeafb" width="80" height="54" class="d-flex align-center justify-center">
                      <v-img height="25" contain src="@/assets/img/empty-link.svg">
                        <v-card tile color="black" width="26" height="26" class="ms-auto d-flex align-center justify-center rounded-tl-lg">
                          <v-img tile class="filter-white" contain height="16" src="@/assets/icon/edit.svg"></v-img>
                        </v-card>
                      </v-img>
                    </v-card>
                  </div>
                  <div>
                    <div class="h7" v-if="i.title">{{ i.title }}</div>
                    <div class="h11 mb-1 text--text" v-if="i.description">{{ i.description }}</div>
                    <div>
                      <a class="url--text h7" :href="i.link" target="_blank">{{ i.link }}</a>
                    </div>
                  </div>
                </div>
                <v-divider class="my-2"></v-divider>
              </div>
            </div>
          </div>
          <div v-if="item.attachments.find((i) => i.type == 'picture')">
            <div class="text--text h8 mt-4">Photos</div>
            <div class="d-flex align-center flex-wrap justify-md-start justify-center">
              <div v-for="i in item.attachments" :key="i.id">
                <v-img
                  @click="openModal(i, item.id)"
                  class="me-1 mt-1 link d-flex align-end"
                  v-if="i.type == 'picture'"
                  height="156"
                  max-width="158"
                  cover
                  :src="i.url"
                >
                  <v-card tile color="black" width="26" height="26" class="ms-auto d-flex align-center justify-center rounded-tl-lg">
                    <v-img tile class="filter-white" contain height="16" src="@/assets/icon/edit.svg"></v-img>
                  </v-card>
                </v-img>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-6 d-flex flex-wrap">
          <v-btn v-if="item.id" @click="update(item)" color="primary" width="130" height="34">SAVE</v-btn>
          <v-btn v-else @click="create(item)" color="primary" width="130" height="34">SAVE</v-btn>
          <v-spacer></v-spacer>
          <v-btn v-if="item.id" @click="removeItem(item.id)" text width="130" height="34" color="error">
            <img src="@/assets/icon/delete.svg" class="mr-1" />Remove
          </v-btn>
        </div>
      </form>
      <v-divider class="hover mt-6 mb-4"></v-divider>
    </div>
    <v-btn v-if="data.length < 15" @click="newItem" color="primary" height="34">
      <v-icon left>mdi-plus</v-icon> ADD UNIVERSITY/COURSE
    </v-btn>
    <v-dialog v-model="modalAddLink" width="568">
      <v-card class="pa-6">
        <div class="d-flex justify-space-between align-center mb-4">
          <div class="h2">Add link</div>
          <v-icon class="black--text" @click="modalAddLink = false">mdi-close</v-icon>
        </div>
        <div class="h11">Link</div>
        <v-text-field
          class="field44"
          v-model="link"
          :error-messages="linkErrors"
          placeholder="http//..."
          outlined
          dense
          clearable
          clear-icon="mdi-close-circle"
          color="secondary"
          height="44"
        ></v-text-field>
        <div class="text-end">
          <v-btn :disabled="!link" @click="saveMedia" height="34" width="140" color="primary">APPLY</v-btn>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="modalMedia" width="568">
      <v-card class="pa-6">
        <div class="d-flex justify-space-between align-center mb-4">
          <div class="h2">Add media</div>
          <v-icon class="black--text" @click="modalMedia = false">mdi-close</v-icon>
        </div>
        <div class="link" @click="addMedia">
          <v-card v-if="!image" height="180" flat tile class="accent pa-3 mb-5 d-flex align-center justify-center">
            <img src="@/assets/img/empty-img.svg" />
          </v-card>
          <img v-else-if="file.type.substr(0, 5) == 'image'" class="d-block mb-5 mx-auto" height="180" :src="image" />
          <v-card tile flat v-else-if="file.type.substr(0, 5) == 'video'" height="180" class="d-flex justify-center align-center mb-5">
            <div class="error--text h3">Sorry, but media type is not support</div>
          </v-card>
          <v-card v-else height="180" flat tile class="accent pa-3 mb-5 d-flex align-center justify-center">
            <img height="80" src="@/assets/img/empty-file.svg" />
          </v-card>
        </div>
        <div class="h11">Title</div>
        <v-text-field
          class="field44"
          v-model="title"
          :error-messages="titleErrors"
          placeholder="Title"
          outlined
          dense
          clearable
          clear-icon="mdi-close-circle"
          color="secondary"
          height="44"
        ></v-text-field>
        <div class="h11 mt-n3">Description</div>
        <v-textarea
          v-model="description"
          :error-messages="descErrors"
          placeholder="Description"
          outlined
          dense
          counter
          clearable
          clear-icon="mdi-close-circle"
          color="secondary"
          auto-grow
          maxlength="600"
        ></v-textarea>
        <div class="text-end">
          <v-btn :disabled="file.type ? file.type.substr(0, 5) == 'video' : ''" @click="saveMedia" height="34" width="140" color="primary">
            APPLY
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="modalEdit" width="568">
      <v-card class="pa-6">
        <div class="d-flex justify-space-between align-center mb-4">
          <div class="h2">Edit {{ objectData.type }}</div>
          <v-icon class="black--text" @click="modalEdit = false">mdi-close</v-icon>
        </div>
        <div class="mb-5">
          <v-card v-if="!objectData.url" height="180" flat tile class="accent pa-3 d-flex align-center justify-center">
            <img height="80" src="@/assets/icon/img.svg" />
          </v-card>
          <div v-else-if="objectData.type == 'pdf'" class="me-3">
            <v-img v-if="objectData.picture" height="180" :src="objectData.picture"></v-img>
            <v-card v-else tile flat height="180" color="#eaeafb" class="d-flex align-center justify-center">
              <img height="80" class="d-block" src="@/assets/img/empty-file.svg" />
            </v-card>
          </div>
          <v-img class="me-3" height="180" contain v-else :src="objectData.url"></v-img>
        </div>
        <div class="h11">Title</div>
        <v-text-field
          class="field44"
          v-model="objectData.title"
          :error-messages="titleErrors"
          placeholder="Title"
          outlined
          dense
          clearable
          clear-icon="mdi-close-circle"
          color="secondary"
          height="44"
        ></v-text-field>
        <div class="h11 mt-n3">Description</div>
        <v-textarea
          v-model="objectData.description"
          :error-messages="descErrors"
          placeholder="Description"
          outlined
          dense
          counter
          clearable
          clear-icon="mdi-close-circle"
          color="secondary"
          auto-grow
          maxlength="600"
        ></v-textarea>
        <div class="d-flex flex-wrap">
          <v-btn @click="deleteMedia" text width="100" height="34" color="error">
            <img src="@/assets/icon/delete.svg" class="mr-1" />Remove
          </v-btn>
          <v-spacer></v-spacer>
          <div class="text-end">
            <v-btn @click="editMedia" height="34" width="140" color="primary">SAVE</v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      attId: '',
      modalAddLink: false,
      modalMedia: false,
      modalEdit: false,
      objectData: {},
      link: '',
      title: '',
      description: '',
      image: '',
      file: '',
      error: [],
    };
  },
  mounted() {
    this.$store.dispatch('getProfileEducation');
  },
  methods: {
    notifi(btn) {
      if (btn == 'error') {
        this.$store.dispatch('setMsg', 'Sorry, but maximum number of education records reached (15 is max)');
        this.$store.dispatch('setColor', 'error');
      }
    },
    addMedia() {
      const input = document.createElement('input');
      const self = this;
      input.setAttribute('type', 'file');
      input.setAttribute('accept', 'image/*,application/pdf');
      input.addEventListener('change', function(e) {
        if (this.files && this.files[0]) {
          self.file = this.files[0];
          var reader = new FileReader();
          reader.onload = (e) => {
            self.image = e.target.result;
          };
          reader.readAsDataURL(this.files[0]);
        }
      });
      input.click();
    },
    async create(item) {
      this.error = [];
      await this.$store.dispatch('addProfileEducation', item).catch((e) => {
        this.error = e.response.data.error;
        if (this.error.find((item) => item == 'education__max_count')) {
          this.notifi('error');
        }
      });
    },
    async update(item) {
      this.error = [];
      await this.$store.dispatch('updateProfileEducationItem', { id: item.id, data: item }).catch((e) => {
        this.error = e.response.data.error;
      });
    },
    newItem() {
      this.data.push({ school: '', degree: '', study_field: '', begin_year: '', end_year: '' });
    },
    async removeItem(id) {
      this.error = [];
      await this.$store.dispatch('deleteProfileEducationItem', id).catch((e) => {
        this.error = e.response.data.error;
      });
    },
    openModal(item, id) {
      this.attId = id;
      this.objectData = item;
      this.modalEdit = true;
    },
    async saveMedia() {
      this.error = [];
      const formData = new FormData();
      this.link ? (formData.append('link', this.link), formData.append('type', 'link')) : '';
      this.title ? formData.append('title', this.title) : '';
      this.description ? formData.append('description', this.description) : '';
      if (this.file) {
        this.file.type.substr(0, 5) == 'image' ? (formData.append('picture', this.file), formData.append('type', 'picture')) : '';
        this.file.type == 'application/pdf' ? (formData.append('pdf', this.file), formData.append('type', 'pdf')) : '';
      }
      await this.$store
        .dispatch('addProfileEducationAttachments', { id: this.attId, data: formData })
        .then(() => {
          this.modalAddLink = false;
          this.modalMedia = false;
        })
        .catch((e) => {
          this.error = e.response.data.error;
        });
    },
    async editMedia() {
      this.error = [];
      const data = new Object();
      data.title = this.objectData.title;
      data.description = this.objectData.description;
      await this.$store
        .dispatch('updateProfileEducationAttachments', { id: this.attId, attId: this.objectData.id, data: data })
        .then(() => {
          this.modalEdit = false;
        })
        .catch((e) => {
          this.error = e.response.data.error;
        });
    },
    async deleteMedia() {
      await this.$store.dispatch('deleteProfileEducationAttachments', { id: this.attId, attId: this.objectData.id }).then(() => {
        this.modalEdit = false;
      });
    },
  },
  watch: {
    data() {
      if (!this.data.length) {
        this.newItem();
      }
    },
    modalAddLink() {
      this.link = '';
      this.error = [];
    },
    modalMedia() {
      this.file = '';
      this.image = '';
      this.title = '';
      this.description = '';
      this.error = [];
    },
  },
  computed: {
    data() {
      return this.$store.getters.profileEducation;
    },
    titleErrors() {
      const errors = [];
      this.error.find((item) => item == 'title__required') && errors.push('Please enter title course');
      this.error.find((item) => item == 'title__invalid') && errors.push('Provided title is not valid (max 50 symbols)');
      return errors;
    },
    descErrors() {
      const errors = [];
      this.error.find((item) => item == 'description__required') && errors.push('Please enter description');
      this.error.find((item) => item == 'description__invalid') && errors.push('Provided description is not valid');
      return errors;
    },
    linkErrors() {
      const errors = [];
      this.error.find((item) => item == 'body__required') && errors.push('Please enter link');
      this.error.find((item) => item == 'link__invalid') && errors.push('Provided link is not valid');
      return errors;
    },
    schoolErrors() {
      const errors = [];
      this.error.find((item) => item == 'school__required') && errors.push('Please enter your school');
      this.error.find((item) => item == 'school__invalid') && errors.push('Provided school is not valid');
      return errors;
    },
    degreeErrors() {
      const errors = [];
      this.error.find((item) => item == 'degree__required') && errors.push('Please enter your degree');
      this.error.find((item) => item == 'degree__invalid') && errors.push('Provided degree is not valid');
      return errors;
    },
    studyErrors() {
      const errors = [];
      this.error.find((item) => item == 'study_field__required') && errors.push('Please enter your study field');
      this.error.find((item) => item == 'study_field__invalid') && errors.push('Provided study field is not valid');
      return errors;
    },
    beginErrors() {
      const errors = [];
      this.error.find((item) => item == 'begin_year__required') && errors.push('Please enter your begin year');
      this.error.find((item) => item == 'begin_year__invalid') && errors.push('Provided begin year is not valid');
      return errors;
    },
    endErrors() {
      const errors = [];
      this.error.find((item) => item == 'end_year__required') && errors.push('Please enter your end year');
      this.error.find((item) => item == 'end_year__invalid') && errors.push('Provided end year is not valid');
      return errors;
    },
  },
};
</script>
