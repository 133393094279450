<template>
  <div>
    <div :class="!data.is_email_confirmed ? 'mb-2' : 'mb-4'" class="h3">Account Settings</div>
    <div class="d-flex">
      <v-card flat tile max-width="320" width="100%">
        <v-card v-if="!data.is_email_confirmed" height="38" tile flat outlined class="confirm-email mb-4 pl-3 pr-2 d-flex align-center">
          <v-icon color="black">mdi-information-outline mdi-18px</v-icon>
          <div class="pl-2 h10">Email is not confirmed</div>
          <v-spacer></v-spacer>
          <v-btn @click="resent" x-small text class="gold--text">Resent</v-btn>
        </v-card>
        <form>
          <div class="h10">First name</div>
          <v-text-field
            class="field44"
            v-model="data.first_name"
            :error-messages="firstNameErrors"
            placeholder="First name"
            clearable
            clear-icon="mdi-close-circle"
            outlined
            dense
            required
            color="secondary"
            height="44"
          ></v-text-field>
          <div class="h10 mt-n2">Last name</div>
          <v-text-field
            class="field44"
            v-model="data.last_name"
            :error-messages="lastNameErrors"
            placeholder="Last name"
            clearable
            clear-icon="mdi-close-circle"
            outlined
            dense
            required
            color="secondary"
            height="44"
          ></v-text-field>
          <v-divider class="hover mt-n1 mb-2"></v-divider>
          <div class="h10 mb-1">Email</div>
          <div class="d-flex align-center">
            <div class="h8" v-if="data.email">{{ data.email }}</div>
            <div class="h8 gray--text" v-else>Your email</div>
            <v-spacer></v-spacer>
            <v-btn @click="modalEmail = true" x-small text color="blue">
              <span v-if="data.email">Change</span><span v-else>Add</span>
            </v-btn>
          </div>
          <v-divider class="hover mt-4 mb-2"></v-divider>
          <div class="h10 mb-1">Phone number</div>
          <div class="d-flex align-center">
            <div class="h8 d-flex align-center" v-if="data.phone_number">
              <img
                class="me-2"
                :src="flagImg(data.phone_country_code.toLowerCase())"
                :srcset="flagImgSet(data.phone_country_code.toLowerCase())"
              />
              <div class="me-1">+{{ flagList.find((item) => item.ct == data.phone_country_code.toLowerCase()).cl }}</div>
              <div>{{ data.phone_number }}</div>
            </div>
            <div class="h8 gray--text" v-else>Your phone</div>
            <v-spacer></v-spacer>
            <v-btn @click="modalPhone = true" x-small text color="blue">
              <span v-if="data.phone_number">Change</span><span v-else>Add</span>
            </v-btn>
          </div>
          <v-divider class="hover mt-4 mb-6"></v-divider>
          <v-btn @click="update" color="primary" width="130" height="34">SAVE</v-btn>
        </form>
      </v-card>
      <div class="mx-auto pl-5">
        <div class="link" @click="changeAvatar">
          <v-card v-if="!data.picture && !image" flat tile class="accent" width="180" height="180">
            <v-icon style="font-size: 180px">mdi-account</v-icon>
          </v-card>
          <v-img cover v-else height="180" max-width="180" :src="image || data.picture"></v-img>
        </div>
        <v-btn :disabled="!file" @click="update" outlined width="140" height="34" class="d-block mx-auto mt-4">Upload an Image</v-btn>
        <v-btn :disabled="!data.picture" @click="deletePhoto" text width="140" height="34" class="d-block mx-auto mt-1" color="error">
          Remove
        </v-btn>
      </div>
    </div>
    <v-dialog v-model="modalEmail" width="568">
      <v-card class="pa-6">
        <div class="h2 mb-4">Сhange email</div>
        <div class="h10">New email address</div>
        <v-text-field
          class="field44"
          v-model="email"
          :error-messages="emailErrors"
          placeholder="user@example.com"
          clearable
          clear-icon="mdi-close-circle"
          outlined
          dense
          required
          color="secondary"
          height="44"
        ></v-text-field>
        <div class="h8 text--text mb-6">
          We will send a confirmation link to the provided email address. Please confirm the provided email address to apply changes.
        </div>
        <div class="text-right">
          <v-btn @click="modalEmail = false" text width="140" height="34">Cancel</v-btn>
          <v-btn @click="updateEmail" class="primary ms-3" width="140" height="34">APPLY</v-btn>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="modalPhone" width="568">
      <v-card class="pa-6">
        <div class="h2 mb-4">Сhange phone number</div>
        <div class="h10">Your phone</div>
        <div class="d-flex">
          <v-autocomplete
            class="field44 phone me-3"
            v-model="countryCode"
            :items="flagList"
            @focus="countryCode = ''"
            @change="$refs.phone.focus()"
            placeholder="Country code"
            append-icon="mdi-chevron-down mdi-18px"
            :item-text="getItemText"
            item-value="ct"
            outlined
            dense
            required
            color="secondary"
            height="44"
            menu-props
          >
            <template v-slot:selection>
              <img class="me-2" :src="flagImg(countryCode)" :srcset="flagImgSet(countryCode)" />
              +{{ flagList.find((item) => item.ct == countryCode).cl }}
            </template>
            <template v-slot:item="data" style="max-width: 300px; width: 100%" class="clip">
              <img class="me-2" :src="flagImg(data.item.ct)" />
              <span style="width: 180px" class="clip">{{ data.item.en }} (+{{ data.item.cl }})</span>
            </template>
          </v-autocomplete>
          <v-text-field
            class="field44"
            v-model="phone"
            :error-messages="phoneErrors"
            placeholder="XX XXX XX XX"
            clearable
            clear-icon="mdi-close-circle"
            ref="phone"
            outlined
            dense
            required
            color="secondary"
            height="44"
            maxlength="10"
          ></v-text-field>
        </div>
        <div class="h8 text--text mb-6">We will send an OTP Code on your new phone number.</div>
        <div class="text-right">
          <v-btn @click="modalPhone = false" text width="140" height="34">Cancel</v-btn>
          <v-btn @click="updatePhone" class="primary ms-3" width="140" height="34">APPLY</v-btn>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="modalOtp" width="368">
      <v-card class="pa-6">
        <div class="h2 mb-4">Enter OTP Code</div>
        <div class="h10">Enter OTP</div>
        <v-text-field
          class="field44"
          v-model="otp"
          :error-messages="otpErrors"
          placeholder="Enter OTP code"
          clearable
          clear-icon="mdi-close-circle"
          outlined
          dense
          required
          color="secondary"
          height="44"
          maxlength="10"
        ></v-text-field>
        <div class="text-right">
          <v-btn @click="modalOtp = false" text width="140" height="34">Cancel</v-btn>
          <v-btn @click="otpRequest" class="primary ms-3" width="140" height="34">APPLY</v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import json from '@/codes.json';

export default {
  data() {
    return {
      flagList: json,
      countryCode: 'sa',
      image: '',
      file: '',
      email: '',
      code: '',
      phone: '',
      otp: '',
      sid: '',
      modalEmail: false,
      modalPhone: false,
      modalOtp: false,
      error: [],
    };
  },
  methods: {
    notifi(btn) {
      if (btn == 'updateProfile') {
        this.$store.dispatch('setMsg', 'Profile is updated successfully');
        this.$store.dispatch('setColor', 'success');
      } else if (btn == 'limitError') {
        this.$store.dispatch('setMsg', 'Sorry, but you are trying to request email confirmation link often. Please try again later');
        this.$store.dispatch('setColor', 'error');
      }
    },
    getItemText(item) {
      return `${item.en} ${item.cl}`;
    },
    flagImg(code) {
      return require(`@/assets/icon/flags/img_flag_${code}.png`);
    },
    flagImgSet(code) {
      return `
        ${require(`@/assets/icon/flags/2x/img_flag_${code}.png`)} 2x,
        ${require(`@/assets/icon/flags/3x/img_flag_${code}.png`)} 3x
      `;
    },
    changeAvatar() {
      const input = document.createElement('input');
      const self = this;
      input.setAttribute('type', 'file');
      input.setAttribute('accept', 'image/*');
      input.addEventListener('change', function(e) {
        if (this.files && this.files[0]) {
          self.file = this.files[0];
          var reader = new FileReader();
          reader.onload = (e) => {
            self.image = e.target.result;
          };
          reader.readAsDataURL(this.files[0]);
        }
      });
      input.click();
    },
    async resent() {
      await this.$store.dispatch('confirmProfile').catch((e) => {
        this.error = e.response.data.error;
        if (this.error.find((item) => item == 'email_confirmation__limit')) {
          this.notifi('limitError');
        }
      });
    },
    async deletePhoto() {
      await this.$store.dispatch('deletePhotoProfile');
    },
    async update() {
      this.error = [];
      const formData = new FormData();
      if (this.file) {
        formData.append('picture', this.file);
      } else {
        formData.append('first_name', this.data.first_name || '');
        formData.append('last_name', this.data.last_name || '');
      }
      await this.$store
        .dispatch('updateProfile', formData)
        .then(() => {
          this.file = '';
          this.image = '';
          this.notifi('updateProfile');
        })
        .catch((e) => {
          this.error = e.response.data.error;
        });
    },
    async updateEmail() {
      this.error = [];
      const formData = new FormData();
      formData.append('email', this.email || '');
      await this.$store
        .dispatch('updateProfile', formData)
        .then(() => {
          this.modalEmail = false;
          this.notifi('updateProfile');
        })
        .catch((e) => {
          this.error = e.response.data.error;
        });
    },
    async updatePhone() {
      this.error = [];
      await this.$store
        .dispatch('newPhoneProfile', { country_code: this.countryCode.toUpperCase(), phone: this.phone })
        .then((res) => {
          this.sid = res.data.sid;
          this.modalPhone = false;
          this.modalOtp = true;
        })
        .catch((e) => {
          this.error = e.response.data.error;
        });
    },
    async otpRequest() {
      this.error = [];
      await this.$store
        .dispatch('newPhoneOtpProfile', { sid: this.sid, otp: this.otp })
        .then(() => {
          this.modalOtp = false;
          this.notifi('updateProfile');
        })
        .catch((e) => {
          this.error = e.response.data.error;
        });
    },
  },
  watch: {
    modalEmail() {
      this.email = '';
      this.error = [];
    },
    modalPhone() {
      // setTimeout(() => {
      //   document.getElementById('code').click();
      // }, 500);
      this.phone = '';
      this.data.phone_country_code ? (this.countryCode = this.data.phone_country_code.toLowerCase()) : '';
      this.error = [];
    },
    modalOtp(val) {
      if (val) {
        this.otp = '';
        this.error = [];
      }
    },
  },
  computed: {
    data() {
      return this.$store.getters.profile;
    },
    firstNameErrors() {
      const errors = [];
      this.error.find((item) => item == 'first_name__required') && errors.push('Please enter your first name');
      this.error.find((item) => item == 'first_name__invalid') && errors.push('Provided first name is not valid');
      return errors;
    },
    lastNameErrors() {
      const errors = [];
      this.error.find((item) => item == 'last_name__required') && errors.push('Please enter your second name');
      this.error.find((item) => item == 'last_name__invalid') && errors.push('Provided second name is not valid');
      return errors;
    },
    emailErrors() {
      const errors = [];
      this.error.find((item) => item == 'email__required') && errors.push('Please enter your email');
      this.error.find((item) => item == 'email__invalid') && errors.push('Provided email is not valid');
      this.error.find((item) => item == 'email__exists') && errors.push('Provided email exist');
      this.error.find((item) => item == 'email__update_too_often') &&
        errors.push('Sorry, but you are trying to update email too often. Please try again later');
      return errors;
    },
    phoneErrors() {
      const errors = [];
      this.error.find((item) => item == 'phone__required') && errors.push('Please enter your phone number');
      this.error.find((item) => item == 'phone__invalid') && errors.push('Provided phone number is not valid');
      this.error.find((item) => item == 'phone__exists') && errors.push('Provided phone number exist');
      this.error.find((item) => item == 'phone__same') && errors.push('The provided phone number is the same as current one');
      this.error.find((item) => item == 'country_code__required') && errors.push('Please enter your country code');
      this.error.find((item) => item == 'contry_code__invalid') && errors.push('Provided country code is not valid');
      this.error.find((item) => item == 'action__limit_exceeded') && errors.push('Sorry, action limit exceeded');
      return errors;
    },
    otpErrors() {
      const errors = [];
      this.error.find((item) => item == 'otp__required') && errors.push('Please enter your otp code');
      this.error.find((item) => item == 'action__limit_exceeded') && errors.push('Sorry, actionmlimit exceeded');
      return errors;
    },
  },
};
</script>

<style scoped>
.confirm-email {
  border: 2px solid #eacb5f;
  background: rgba(234, 203, 95, 0.04) !important;
  border-radius: 2px !important;
}
.gold--text {
  color: #eacb5f;
}
</style>
